export const POPUP_URL = 'https://ecom.wix.com/storefront/cartwidgetPopup';
export const SECTION_ID = 'shopping_cart';
export const SERVER_PORT = 5000;
export const CART_ICON_DSN = 'https://31e500c94d2042ff81a38a0e4a2873b7@sentry.io/1252955';
export const EMPTY_CART_GUID = '00000000-000000-000000-000000000000';
export const GRAPHQL_URL = '_api/wix-ecommerce-graphql-web/api';
export const specs = {
  stores: {
    OutOfIFrameRemoveCartId: 'specs.stores.OutOfIFrameRemoveCartId',
    FetchMultilingualGraphql: 'specs.stores.FetchMultilingualGraphql',
  },
};
export const SENTRY_DSN = 'https://31e500c94d2042ff81a38a0e4a2873b7@sentry.io/1252955';

export const cartIconTranslationPath = (baseUrl, locale) => `${baseUrl}assets/locale/cartIcon/cartIcon_${locale}.json`;
